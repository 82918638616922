import React, { useCallback } from "react";
import { debounce } from "lodash";

import { Grid, Typography, Accordion, IconButton, AccordionSummary, AccordionDetails, TextField, MenuItem, Tooltip, Menu, Button, Divider } from "@mui/material";
import { AddCircle, Delete, DragIndicator, ExpandMore, FileCopy, MoreVert, Send } from "@mui/icons-material";

import { useProjectStyles } from "./projectStyle.js";
import { getFlats, editFlat, duplicateFlat, deleteFlat } from "./projectController.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import { reorderLevel, selectInput, sortObjByItem } from "../../store/gloablFunctions.js";
import { Format, _t } from "../../store/translate.js";
import { getRoomsWithExtras } from "./rooms.js";
import { Draggable } from "react-beautiful-dnd";

export function Flat(props) {
	const {
		vendor,
		project,
		getStateFloors,
		floors,
		floor,
		findFloor,
		setFloors,
		flat,
		findFlat,
		deleteArrayElementById,
		setLightLoading,
		changeCalculatedMaterialsState,
		handleClick_OpenAddRoomsModal,
		getItemStyle,
		index,
	} = props;

	const projectsStyle = useProjectStyles();

	/* Flats */
	const handleExpandChange_Flat = async (event, floorId, flatId) => {
		if (!event.target.closest("button") && event.target.nodeName !== "INPUT" && !event.target.classList.contains("noExpand")) {
			var newFloors = getStateFloors();
			var foundFlat = findFlat(newFloors, floorId, flatId);

			if (!foundFlat.rooms) {
				setLightLoading(true);
				var rooms = await getRoomsWithExtras(flatId, project);
				foundFlat.rooms = rooms;
				setLightLoading(false);
			}
			foundFlat.expanded = !foundFlat.expanded;
			setFloors(newFloors);
		}
	};
	const handleClick_DuplicateFlat = async (floorId, flatId) => {
		setLightLoading(true);
		var request = await duplicateFlat(flatId, floorId);
		var newFloors = getStateFloors();
		var foundFloor = newFloors.find((f) => f._id === floorId);

		// send to floor when flats not loaded
		if (!foundFloor.flats) {
			var flats = await getFlats(floorId);
			foundFloor.flats = flats;
			foundFloor.expanded = !foundFloor.expanded;
		} else {
			foundFloor.flats.push(request);
			foundFloor.flats = sortObjByItem(foundFloor.flats, "level");
			changeCalculatedMaterialsState();
		}
		reorderLevel(foundFloor.flats);
		setFloors(newFloors);
		setLightLoading(false);
	};
	const handleEdit_Flat = async (attribute, value, floorId, flatId) => {
		if ((attribute === "flatCount" && value !== "" && value >= 0) || attribute === "flatName") {
			var newFloors = getStateFloors();
			var foundFlat = findFlat(newFloors, floorId, flatId);
			if (attribute === "flatCount") {
				foundFlat.count = value;
			}
			if (attribute === "flatName") {
				foundFlat.name = value;
			}
			setFloors(newFloors);
			debounce_Flat(foundFlat);
		}
	};
	const handleClick_toggleMenu = (currentTarget, floorId, flatId) => {
		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);
		foundFlat.menu_anchorEl = currentTarget;
		setFloors(newFloors);
	};
	const debounce_Flat = useCallback(
		debounce(async (foundFlat) => {
			foundFlat.menu_anchorEl = null;
			await editFlat(foundFlat._id, foundFlat);
			changeCalculatedMaterialsState();
		}, 1000),
		[]
	);

	/* Delete Modal */
	const [deleteModal, setDeleteModal] = React.useState(false);
	const [toDeleteFlat, setToDeleteFlat] = React.useState({});
	const handleClick_OpenDeleteModal = async (flat) => {
		setToDeleteFlat(flat);
		setDeleteModal(true);
	};
	const handleClick_ConfirmDeleteFlat = async () => {
		var floorId = toDeleteFlat.floor;
		var flatId = toDeleteFlat._id;
		setLightLoading(true);
		await deleteFlat(flatId);
		var newFloors = getStateFloors();
		var foundFloor = findFloor(newFloors, floorId);
		foundFloor.flats = deleteArrayElementById(foundFloor.flats, flatId);
		setFloors(newFloors);
		changeCalculatedMaterialsState();
		setLightLoading(false);
	};

	/* Send Modal */
	const [sendModal, setSendModal] = React.useState(false);
	const [toSend, setToSend] = React.useState({ name: "", to: "", selections: [], toSelected: "", item: "" });
	const SendModalContent = (
		<Grid container>
			<TextField
				select
				label={toSend.to}
				variant="outlined"
				value={toSend.toSelected}
				onChange={(event) => {
					setToSend({
						name: toSend.name,
						to: toSend.to,
						selections: toSend.selections,
						toSelected: event.target.value,
						item: toSend.item,
					});
				}}
			>
				{toSend.selections.map((selection) => (
					<MenuItem key={selection._id} value={selection._id}>
						{selection.name}
					</MenuItem>
				))}
			</TextField>
		</Grid>
	);
	const handleClick_openSendModal = (name, selections, toSelected, item) => {
		var to = _t("Etage");
		setToSend({ name, to, selections, toSelected, item });
		setSendModal(true);
	};
	const handleClick_sendOnConfirmation = async () => {
		var floorId;
		// if (toSend.name === _t("Raum")) {
		// 	floorId = toSend.item.floor;
		// 	var flatId = toSend.toSelected;
		// 	var room = toSend.item;
		// 	await handleClick_DuplicateRoom(floorId, flatId, room);
		// } else
		if (toSend.name === _t("Wohnung")) {
			floorId = toSend.toSelected;
			var flat = toSend.item;
			await handleClick_DuplicateFlat(floorId, flat._id);
			console.log(toSend);
		}
	};

	return (
		<Grid className={projectsStyle.accordion}>
			{sendModal && (
				<ModalDialog
					vendor={vendor}
					opened={true}
					closed={setSendModal}
					title={Format(_t("%1 senden:"), [toSend.name])}
					acceptbutton={handleClick_sendOnConfirmation}
					children={SendModalContent}
				/>
			)}
			{deleteModal && (
				<ModalDialog
					vendor={vendor}
					opened={true}
					closed={setDeleteModal}
					title={Format(_t("%1 löschen?"), [toDeleteFlat.name])}
					acceptbutton={handleClick_ConfirmDeleteFlat}
				/>
			)}
			<Draggable key={flat._id} draggableId={flat.floor + "," + flat._id} index={index}>
				{(providedFlat, snapshotFlat) => {
					return (
						<Accordion
							style={getItemStyle(snapshotFlat.isDragging, providedFlat.draggableProps.style)}
							ref={providedFlat.innerRef}
							{...providedFlat.draggableProps}
							expanded={!!flat.expanded}
							onChange={(event) => {
								handleExpandChange_Flat(event, floor._id, flat._id);
							}}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Grid container item direction="row" justifyContent="space-between" alignItems="center">
									<Grid item>
										<Grid container item alignItems="center" spacing={1}>
											<Grid item>
												{flat.count > 1 && (
													<Typography component="div" variant="body1">
														({flat.count}×)
													</Typography>
												)}
											</Grid>
											{flat.expanded && (
												<Grid item>
													{/* <Button
														className={projectsStyle.buttonWithIcon}
														variant="contained"
														color="primary"
														// className={projectsStyle.addButton}
														onClick={(e) => {
															handleClick_OpenAddRoomsModal(floor._id, flat._id);
														}}
													>
														<AddCircle />
														<Typography variant="body1">{_t("Raum")}</Typography>
													</Button> */}
													<Tooltip title={_t("Neue Räume")}>
														<IconButton
															color="primary"
															onClick={(e) => {
																handleClick_OpenAddRoomsModal(floor._id, flat._id);
															}}
															size="large"
														>
															<AddCircle />
														</IconButton>
													</Tooltip>
												</Grid>
											)}
											<Grid item>
												<TextField
													variant="standard"
													value={flat.name}
													className={"nakedInput"}
													onClick={selectInput}
													onChange={(event) => {
														handleEdit_Flat("flatName", event.target.value, floor._id, flat._id);
													}}
													autoFocus={!!flat.expanded}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container item justifyContent="flex-end">
											<Tooltip title={_t("Mehr anzeigen")}>
												<IconButton
													size="small"
													aria-label="more"
													aria-controls="long-menu"
													aria-haspopup="true"
													onClick={(event) => {
														event.stopPropagation();
														handleClick_toggleMenu(event.currentTarget, floor._id, flat._id);
													}}
												>
													<MoreVert />
												</IconButton>
											</Tooltip>
											<Menu
												id="long-menu"
												anchorEl={flat.menu_anchorEl}
												keepMounted
												open={Boolean(flat.menu_anchorEl)}
												onClose={(event) => {
													event.stopPropagation();
													handleClick_toggleMenu(null, floor._id, flat._id);
												}}
												className={projectsStyle.longMenu}
											>
												<MenuItem>
													<TextField
														type="number"
														label={Format(_t(`Multiplikator für %1`), [flat.name])}
														variant="outlined"
														fullWidth
														value={flat.count}
														inputProps={{ min: 1, style: { textAlign: "right" } }}
														className={("changeFlatCount", projectsStyle.menuCount)}
														onClick={selectInput}
														onWheel={(event) => {
															event.target.blur();
														}}
														onChange={(event) => {
															handleEdit_Flat("flatCount", event.target.value, floor._id, flat._id);
														}}
													/>
												</MenuItem>
												<br />
												<Divider />
												<MenuItem>
													<Button
														startIcon={<FileCopy />}
														color="primary"
														onClick={(e) => {
															handleClick_DuplicateFlat(floor._id, flat._id);
														}}
														style={{ justifyContent: "flex-start" }}
													>
														<Typography variant="body1">{_t("Wohnung kopieren")}</Typography>
													</Button>
												</MenuItem>
												<MenuItem>
													<Button
														startIcon={<Send />}
														color="primary"
														onClick={(e) => {
															handleClick_openSendModal("Wohnung", floors, floor._id, flat);
														}}
														style={{ justifyContent: "flex-start" }}
													>
														<Typography variant="body1">{_t("Wohnung senden")}</Typography>
													</Button>
												</MenuItem>
												<MenuItem>
													<Button
														startIcon={<Delete />}
														color="error"
														onClick={(e) => {
															handleClick_OpenDeleteModal(flat);
														}}
														style={{ justifyContent: "flex-start" }}
													>
														<Typography variant="body1">{_t("Wohnung löschen")}</Typography>
													</Button>
												</MenuItem>
											</Menu>
											<Tooltip title={_t("Verschieben")} {...providedFlat.dragHandleProps}>
												<IconButton size="small">
													<DragIndicator />
												</IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
							</AccordionSummary>
							<AccordionDetails style={{ background: "#f9f9f9" }}>
								<Grid item xs={12}>
									{props.children}
								</Grid>
							</AccordionDetails>
						</Accordion>
					);
				}}
			</Draggable>
		</Grid>
	);
}
