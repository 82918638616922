import { makeStyles } from "@mui/styles";

export const useProjectSettingsStyles = makeStyles((theme) => ({
	root: {
		"& .MuiFormControl-root.MuiTextField-root": {
			width: "100%",
		},
		"& .MuiButtonBase-root": {
			margin: theme.spacing(1),
		},
		"& .MenuWithImage .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
			width: "100%",
			display: "flex",
			position: "relative",
			boxSizing: "border-box",
			textAlign: "left",
			alignItems: "center",
			paddingTop: "8px",
			paddingBottom: "8px",
			justifyContent: "flex-start",
			textDecoration: "none",
			flexDirection: "row",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		"& .hideOnSmall": {
			visibility: "hidden",
			[theme.breakpoints.down("md")]: {
				display: "none",
			},
		},
		"& .MuiMenuItemText-root > span": {
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	},
}));
