import React from "react";
// import { useNavigate } from "react-router-dom";

import { Grid, Link, Typography } from "@mui/material";

import { useMainStyles } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { _t } from "../../store/translate.js";

function Imprint(props) {
	const mainStyle = useMainStyles(props.vendor.postfix)();

	const vendorList = {
		//TODO
		buderus: <Grid container direction="column" className={mainStyle.gridPadding}></Grid>,
		henco: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				Gegevens Henco
				<br />
				Henco Industries NV
				<br />
				Toekomstlaan 27
				<br />
				2200 Herentals
				<br />
				Belgium
				<br />
				<br />
				Phone : +32 14 28 56 60
				<br />
				Fax : +32 14 21 87 12
				<br />
				E-mail : info@henco.be
				<br />
				<br />
				VAT BE0443.598.222
				<br />
				BNP Paribas Fortis
				<br />
				IBAN: BE61 2300 0806 0417
				<br />
				BIC: GEBABEBB
				<br />
				<br />
				Hencofloor NV
				<br />
				Toekomstlaan 27
				<br />
				2200 Herentals
				<br />
				Belgium
				<br />
				<br />
				Phone : +32 14 28 56 60
				<br />
				Fax : +32 14 23 33 64
				<br />
				E-mail : info@hencofloor.be
				<br />
				<br />
				<br />
				<br />
				Web application developed and provided by
				<br />
				LINEAR GmbH
				<br />
				Im Suesterfeld 20
				<br />
				52072 Aachen
				<br />
				GERMANY
				<br />
				Commercial register: Amtsgericht Aachen HRB 5701
				<br />
				VAT-ID No.: DE160367433.
				<br />
				Management board: Manfred Waluga, Javier Castell Codesal and Juergen Frantzen
				<br />
				<br />
				<br />
			</Grid>
		),
		henco_nl: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				Gegevens Henco
				<br />
				Henco Industries NV
				<br />
				Toekomstlaan 27
				<br />
				2200 Herentals
				<br />
				Belgium
				<br />
				<br />
				Phone : +32 14 28 56 60
				<br />
				Fax : +32 14 21 87 12
				<br />
				E-mail : info@henco.be
				<br />
				<br />
				VAT BE0443.598.222
				<br />
				BNP Paribas Fortis
				<br />
				IBAN: BE61 2300 0806 0417
				<br />
				BIC: GEBABEBB
				<br />
				<br />
				Hencofloor NV
				<br />
				Toekomstlaan 27
				<br />
				2200 Herentals
				<br />
				Belgium
				<br />
				<br />
				Phone : +32 14 28 56 60
				<br />
				Fax : +32 14 23 33 64
				<br />
				E-mail : info@hencofloor.be
				<br />
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr.: DE160367433.
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer: Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
			</Grid>
		),
		ivt: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				IVT Installations- und Verbindungstechnik GmbH & Co. KG <br /> Gewerbering Nord 5 <br /> D-91189 ROHR <br /> DEUTSCHLAND <br /> Telefon +49 9876 9786-0 <br />{" "}
				Telefax +49 9876 9786-90 <br /> info@ivt-group.com
				<h3>Hauptsitz Deutschland</h3>
				Steuer-Nr.: 76001/12916 <br /> Ust. Id. Nr.: DE 170458863 <br /> HRA Nürnberg 10791 <br /> Amtsgericht Schwabach <br />
				<h3>Betriebsstätte Österreich</h3>
				Ust. Id. Nr.: ATU 57676955
				<br />
				Steuernummer: 030/1318, Ref. 03
				<br />
				Registergericht Wien FN 242774x
				<br />
				<h3>Persönliche haftende Gesellschafterin</h3>
				IVT Installations- und Verbindungstechnik Verwaltungs GmbH
				<br />
				Sitz der Gesellschaft: 91189 Rohr, Deutschland
				<br />
				HRB Nürnberg 12992 Amtsgericht Schwabach
				<br />
				<h3> Geschäftsführer</h3>
				Ingrid Zürn
				<br />
				Ralf Stottok
				<br />
				Anbieter der Software ist auch die <br /> LINEAR Gesellschaft für konstruktives Design mbH <br /> Im Süsterfeld 20 <br /> 52072 Aachen <br /> HR: Amtsgericht Aachen
				HRB 5701 <br /> USt-ID-Nr.: DE160367433. <br /> Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen
				vertreten.
			</Grid>
		),
		kermi: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				<h4> Angaben gemäß § 5 TMG:</h4>
				Kermi GmbH
				<br />
				Pankofen-Bahnhof 1
				<br />
				94447 Plattling
				<h4> Vertreten durch:</h4>
				Geschäftsführer:
				<br />
				Knut Bartsch (Vorsitzender)
				<br />
				Reiner Habermann
				<br />
				Alexander Kaiß
				<br />
				<h4> Kontakt:</h4>
				Telefon: +49 9931 501-0
				<br />
				Telefax: +49 9931 3075
				<br />
				E-Mail: info@kermi.de
				<br />
				<Grid>
					Kermi:{" "}
					<Link href="http://www.kermi.de/de/Globale_Navigation/Kontakte/index.phtml" target="_blank" rel="noopener noreferrer">
						{_t("Ansprechpartner")}
					</Link>
				</Grid>
				<Grid>
					Internet:{" "}
					<Link href="http://www.kermi.de" target="_blank" rel="noopener noreferrer">
						www.kermi.de
					</Link>
				</Grid>
				<h4> Registereintrag:</h4>
				Eintragung im Handelsregister.
				<br />
				Registergericht: Handelsregister Deggendorf
				<br />
				Registernummer: HRB 0127
				<h4> Umsatzsteuer-Identifikationsnummer:</h4>
				gemäß § 27 a Umsatzsteuergesetz: DE 811129898
				<h4> Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h4>
				Thilo Wiedeking
				<br />
				Pankofen-Bahnhof 1
				<br />
				94447 Plattling
			</Grid>
		),
		"p&m": (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				PFEIFFER & MAY SE <br /> Unterweingartenfeld 7 <br /> 76135 Karlsruhe <br /> DEUTSCHLAND <br />
				Telefon (Ausstellung): +49 (0) 721 8602 - 240 <br />
				Telefon (Verkauf): +49 (0) 721 8602 - 0 <br />
				Telefon (Verwaltung): +49 (0) 721 98652 - 0 <br />
				URL: www.pfeiffer-may.de
				<h3>Vertretungsberechtigter Vorstand:</h3>
				Ulf Steinhart
				<br />
				Markus Bury
				<br />
				<h3>Aufsichtsratsvorsitzender:</h3>
				Kai Markert
				<br />
				<h3>Sitz der Gesellschaft:</h3>
				Karlsruhe
				<br />
				<h3>Registergericht:</h3>
				Amtsgericht Mannheim
				<br />
				<h3>Registernummer: </h3>
				HRB712580
				<br />
				<h3>Umsatzsteuer-Ident-Nr.: </h3>
				DE160290082
				<br />
				Anbieter der Software ist auch die <br /> LINEAR Gesellschaft für konstruktives Design mbH <br /> Im Süsterfeld 20 <br /> 52072 Aachen <br /> HR: Amtsgericht Aachen
				HRB 5701 <br /> USt-ID-Nr.: DE160367433. <br /> Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen
				vertreten.
			</Grid>
		),
		roth: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				Am Seerain
				<br />
				35232 Dautphetal-Buchenau
				<br />
				<br />
				Telefon: (+49) 64 66 / 9 22-0
				<br />
				Telefax: (+49) 64 66 / 9 22-1 00
				<br />
				<br />
				<Grid>
					Internet:{" "}
					<Link href="http://www.roth-werke.de/" target="_blank" rel="noopener noreferrer">
						http://www.roth-werke.de/
					</Link>
				</Grid>
				<br />
				E-Mail: service(at)roth-werke.de
				<br />
				<br />
				Vertretungsberechtigte Geschäftsführer:
				<br />
				Alfred Kajewski, Christin Roth-Jäger
				<br />
				<br />
				Registergericht: Amtsgericht Marburg
				<br />
				Registernummer: HRB 2726
				<br />
				<br />
				Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:
				<br />
				DE 811225861
				<br />
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr.: DE160367433.
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer: Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
			</Grid>
		),
		//TODO
		schuetz: <Grid container direction="column" className={mainStyle.gridPadding}></Grid>,
		somatherm: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				Somatherm S.A.S
				<br />
				<br />
				13, place Francheville
				<br />
				BP 202 – 24052 Périgueux
				<br />
				CT Cedex 9 (France)
				<br />
				<br />
				Tél : 05 53 02 69 70
				<br />
				Fax : 05 53 03 94 48
				<br />
				E-mail : contact@somatherm.fr
				<br />
				<br />
				Siret : 68198003300010
				<br />
				Responsable : M. HAMMEL Raymond
				<br />
				<br />
				Application web développée et fournie par
				<br />
				LINEAR GmbH
				<br />
				Im Suesterfeld 20
				<br />
				52072 Aachen
				<br />
				ALLEMAGNE
				<br />
				Registre du commerce : Amtsgericht Aachen HRB 5701 HRB 5701
				<br />
				N° d'identification TVA : DE16036747433.
				<br />
				Conseil d'administration : Manfred Waluga, Javier Castell Codesal et Juergen Frantzen
				<br />
				<br />
				<br />
			</Grid>
		),
		viega: (
			<Grid container direction="column" className={mainStyle.gridPadding}>
				Viega Holding GmbH & Co. KG
				<br />
				Postfach 430/440
				<br />
				57428 Attendorn oder: Viega Holding GmbH & Co. KG
				<br />
				Viega Platz 1
				<br />
				57428 Attendorn
				<br />
				info@viega.de
				<br />
				Tel: +49 2722 61-0
				<br />
				Fax: +49 2722 61-1415 USt-IdNr. DE126180387
				<br />
				Kommanditgesellschaft, Sitz Attendorn, Amtsgericht Siegen HRA 7404,
				<br />
				Persönlich haftende Gesellschafterin: Viega Holding Beteiligungs GmbH,
				<br />
				Geschäftsführer: Walter Viegener, Claus Holst-Gydesen
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr.: DE160367433.
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer: Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
			</Grid>
		),
		//TODO
		viessmann: <Grid container direction="column" className={mainStyle.gridPadding}></Grid>,
	};

	return (
		<Layout>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
					<Typography component="h4" variant="h4">
						{_t("Impressum")}
					</Typography>
				</Grid>
				{vendorList[props.vendor.postfix]}
			</Grid>
		</Layout>
	);
}

export default Imprint;
