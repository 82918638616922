import { makeStyles } from "@mui/styles";

export const useLayoutStyles = makeStyles((theme) => ({
	main: {
		padding: "79px 20px 0px 20px",
		minHeight: "calc(100vh - 40px)",
		backgroundColor: "#fefefe",
	},

	body: {
		fontFamily:
			'-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol","Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",		"Droid Sans", "Helvetica Neue", "sans-serif"',
		fontSize: "14px",
		lineHeight: "1.5",
		color: "#24292e",
		backgroundColor: "#fff",
		WebkitFontSmoothing: "antialiased",
		MozOsxFontSmoothing: "grayscale",
		margin: "0",
	},
	item: {
		flexGrow: "1",
	},
}));
