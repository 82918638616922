import { makeStyles } from "@mui/styles";

import Images from "./store/images.js";

export const useMainStyles = (vendor) =>
	makeStyles((theme) => ({
		accountSettingContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			padding: "10px 0px 15px",
		},
		image: {
			backgroundImage: `url(${Images(vendor).background})`,
			backgroundRepeat: "no-repeat",
			backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
			backgroundSize: "cover",
			boxShadow: theme.shadows[6],
		},
		paper: {
			margin: theme.spacing(8, 4),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main,
		},
		form: {
			width: "90%", // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
		},
		gridPadding: {
			padding: "10px 0px 15px",
		},
		vendorStyles: {
			//Buttons contained
			"& .MuiButton-containedPrimary:not(.Mui-disabled)": {
				//"#3f51b5" is standard primary color
				backgroundColor: Images(vendor).primaryButtonColor || "#3f51b5",
			},
			//Buttons
			"& .MuiButtonBase-colorPrimary:not(.Mui-disabled), .MuiSvgIcon-colorPrimary:not(.Mui-disabled)": {
				color: Images(vendor).primaryButtonColor || "#3f51b5",
			},
			//LockOutlinedIcon
			"& .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault":{
				backgroundColor: Images(vendor).primaryButtonColor || "#3f51b5",
			},
		},
	}));
