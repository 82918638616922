import { makeStyles } from "@mui/styles";

export const useAdministrationStyles = makeStyles((theme) => ({
	root: {
		"& >:nth-child(odd)": {
			background: "#f0f0f0",
		},
		"& > *": {
			padding: "10px",
		},
	},
}));
