import { makeStyles } from "@mui/styles";

export const useMaterialStyles = makeStyles((theme) => ({
	root: {
		// height: "500px",
		// width:"100%",
		"& .MuiTableHead-root": {
			background: "#ededed",
			boxShadow: theme.shadows[3],
			zIndex: 1,
		},
		"& tbody tr:nth-child(odd)": {
			background: "#fcfcfc",
		},
		"& tbody > .MuiTableRow-root:hover": {
			background: "#f7f7f7",
		},
		margin: "20px 0",

		//disable input number arrows => Chrome, Safari, Edge, Opera
		"& input::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0,
		},
		//disable input number  => Firefox
		"& input[type=number]": {
			"-moz-appearance": "textfield",
		},
	},
	totalCost: {
		paddingRight: "30px",
		"& >:first-child": {
			paddingRight: "10px",
		},
		"& >:last-child": {
			borderBottom: "1px solid black",
		},
	},
}));
