import { makeStyles } from "@mui/styles";

export const useProjectListStyles = makeStyles((theme) => ({
	root: {
		"& .MuiDataGrid-columnHeaders": {
			background: "#ededed",
			boxShadow: theme.shadows[3],
			zIndex: 1,
		},
		"& .MuiDataGrid-row.Mui-odd": {
			background: "#ededef2f",
		},
		"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
			background: "#f7f7f7",
		},
		"& .MuiDataGrid-cell.MuiDataGrid-cellLeft": {
			cursor: "pointer",
		},
	},
}));
