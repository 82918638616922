import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Grid, CssBaseline } from "@mui/material";

import Footer from "./footer/footer.js";
import Header from "./header/header.js";
import { Copyright } from "./copyright/copyright.js";
import { useLayoutStyles } from "./layoutStyle.js";
import { useMainStyles } from "../mainStyle.js";

import { ModalDialog } from "../components/shared/modals/modalDialog.js";

function App(props) {
	const layoutStyle = useLayoutStyles();
	const mainStyle = useMainStyles(props.vendor.postfix)();

	React.useEffect(() => {
		async function fetchData() {
			if (props?.vendor?.showModal) {
				props.closed();
			}
		}
		fetchData();
	}, []);

	return (
		<Fragment>
			<div className={`${layoutStyle.body} ${mainStyle.vendorStyles}`}>
				<Header />
				<CssBaseline />
				<Grid component="main" role="main" className={layoutStyle.main} container direction="column" justifyContent="center" alignItems="center" item xs={12}>
					<Grid container item className={`${layoutStyle.item} ${mainStyle.vendorStyles}`}>
						{props?.vendor?.showModal && (
							<ModalDialog
								vendor={props.vendor}
								opened={props?.vendor?.showModal}
								closed={props.closed}
								title={props.vendor.modalTitle}
								text={props.vendor.modalText}
							/>
						)}
						{props.children}
					</Grid>
					<Grid item container justifyContent="flex-end">
						<Copyright />
					</Grid>
				</Grid>
				<Footer />
			</div>
		</Fragment>
	);
}
const mapStateToProps = (state) => {
	return {
		vendor: state.vendor,
		user: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closed: () => dispatch({ type: "error_modal", payload: { show: false, title: "", text: "" } }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
