import React from "react";
// import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { useMainStyles } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";

function ErrorPage(props) {
	const mainStyle = useMainStyles(props?.vendor?.postfix)();

	// const navigate = useNavigate();
	if (props?.noLayout) {
		return (
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
					<Typography component="h4" variant="h4">
						{props?.error?.title}
					</Typography>
				</Grid>
				<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
					<Typography component="h6" variant="h6">
						{props?.error?.text}
					</Typography>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Layout>
				<Grid item xs={12}>
					<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
						<Typography component="h4" variant="h4">
							{props?.error?.title}
						</Typography>
					</Grid>
					<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
						<Typography component="h6" variant="h6">
							{props?.error?.text}
						</Typography>
					</Grid>
				</Grid>
			</Layout>
		);
	}
}

export default ErrorPage;
