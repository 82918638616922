export const vendorList = [
	// vogelnoot, uponor ?
	// preferences: { has_prices: true },
	{
		name: "Buderus",
		secret: "vi841",
		postfix: "buderus",
		short_form: "BUFH",
		title: "Buderus Logafloor Planungstool",
		languages: ["de"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "logafloor.buderus.de",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: false,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "Henco",
		secret: "ha8s7",
		postfix: "henco",
		short_form: "HENC",
		title: "Henco Online",
		languages: ["en", "nl", "fr", "ru"],
		active: false,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "en",
		url: "",
		address: [],
		preferences: {
			minimum_packaging_quantity: true,
			calculate_valve: false,
			has_prices: true,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "Henco",
		secret: "he3n9",
		postfix: "henco_nl",
		short_form: undefined,
		title: "Henco Online",
		languages: ["nl"],
		active: false,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "nl",
		url: "",
		address: [],
		preferences: {
			minimum_packaging_quantity: true,
			calculate_valve: false,
			has_prices: true,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "IVT",
		secret: "ubcn",
		postfix: "ivt",
		short_form: "IVTP",
		languages: ["de", "en"],
		title: "IVT Online",
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "www.ivt-flaechenheizung-schnellauslegung.de",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: true,
			privacy: false,
			vip: false,
		},
	},
	{
		name: "Kermi",
		secret: "ktghg",
		postfix: "kermi",
		short_form: "KER_",
		title: "X-net Webauslegung",
		languages: ["de", "en"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "xnet.kermi.de/",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: true,
			privacy: false,
			vip: false,
		},
	},
	// {
	// 	name: "oventrop",
	// 	secret: "dlsd",
	// 	postfix: "oventrop",
	// 	// short_form: "",
	// 	languages: ["de"],
	// 	active: false,
	// 	email: "webdev@linear.eu",
	// 	no_reply_email: "noreply@linear.eu",
	// 	default_language: "de",
	// 	address: [],
	// },
	{
		name: "Pfeiffer & May",
		secret: "pfuma",
		postfix: "p&m",
		short_form: "PMVN",
		title: "XtraFloor",
		languages: ["de"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "xtrafloor.pfeiffer-may.de",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: true,
			privacy: false,
			vip: true,
			verification_from_linear: true,
		},
	},
	{
		name: "Purmo",
		secret: "purmo",
		postfix: "purmo",
		short_form: "PURM",
		title: "Purmo FBH Online-Schnellauslegung",
		languages: ["de", "en"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "schnellauslegung.purmo.de",
		address: [],
		//TODO preferences okay?
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: true,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "Vogel&Noot",
		secret: "vogel",
		postfix: "v&n",
		short_form: "VUNN",
		title: "VOGEL&NOOT FBH Online-Schnellauslegung",
		languages: ["de", "en"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "schnellauslegung.vogelundnoot.com",
		address: [],
		//TODO preferences okay?
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: true,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "COSMO",
		secret: "cosmo",
		postfix: "cosmo",
		short_form: "CGVN",
		title: "COSMO FBH Schnellauslegung",
		languages: ["de"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "fbh-auslegung.cosmo-info.de",
		address: [],
		//TODO preferences okay?
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: true,
			has_prices: true,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "Roth",
		secret: "rosdf",
		postfix: "roth",
		short_form: "ROTH",
		title: "Roth Online",
		languages: ["en", "nl", "de"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "quickcal.roth-werke.de",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: false,
			has_prices: true,
			privacy: false,
			vip: false,
		},
	},
	{
		name: "Schütz",
		secret: "lavlk",
		postfix: "schuetz",
		short_form: "SCHUTZ",
		title: "SCHÜTZ Schnellauslegung",
		languages: ["de"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		urls: ["quickcal.schuetz.net", "schnellauslegung.schuetz.net"],
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: false,
			has_prices: false,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "Somatherm",
		secret: "s3m4t",
		postfix: "somatherm",
		short_form: "SOMA",
		title: "Somatherm Online",
		languages: ["fr"],
		active: false,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "fr",
		url: "",
		address: [],
		preferences: {
			minimum_packaging_quantity: true,
			calculate_valve: false,
			has_prices: true,
			privacy: false,
			vip: true,
		},
	},
	{
		name: "Viega",
		secret: "ac547",
		postfix: "viega",
		short_form: "VIEG",
		title: "Fonterra Schnellauslegung",
		languages: ["de"],
		active: true,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "fonterra-schnellauslegung.viega.de",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: false,
			has_prices: false,
			privacy: false,
			vip: false,
		},
	},
	{
		name: "Viessmann",
		secret: "832tr",
		postfix: "viessmann",
		short_form: "VIES",
		// title: "",
		languages: ["de"],
		active: false,
		email: "webdev@linear.eu",
		no_reply_email: "noreply@linear.eu",
		default_language: "de",
		url: "",
		address: [],
		preferences: {
			minimum_packaging_quantity: false,
			calculate_valve: false,
			has_prices: false,
			privacy: false,
			vip: false,
		},
	},
];
