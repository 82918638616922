import { makeStyles } from "@mui/styles";

export const useHomeStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: "20px",
	},
	paper: {
		height: 140,
		width: 100,
	},
	control: {
		padding: theme.spacing(2),
	},
	app: {
		backgroundSize: "cover",
	},
	gridList: {
		width: "100%",
	},
	icon: {
		color: "rgba(255, 255, 255, 0.54)",
	},
	featureText: {
		color: "white",
		position: "absolute",
		zIndex: 10,
		padding: "10px",
		height: "100%",
		overflowY: "scroll",
	},
}));
