import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";

const LinearTextInput = ({ label, type, margin = "normal", id = label, helperText, error = false, fullParentWidth = false, required, value, disabled }) => {
	const [showPassword, setShowPassword] = useState(false);
	const isPasswordField = type === "password";

	return (
        <TextField
			value={value}
			disabled={disabled}
			required={required}
			fullWidth={fullParentWidth}
			id={id}
			margin={margin}
			label={label}
			variant="outlined"
			name={type}
			type={isPasswordField ? (showPassword ? "text" : "password") : type}
			autoComplete={isPasswordField ? "current-password" : type}
			helperText={helperText}
			error={error}
			InputProps={
				isPasswordField
					? {
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((value) => !value)}
                                        onMouseDown={() => setShowPassword((value) => !value)}
                                        edge="end"
                                        size="large">
										{showPassword ? <VisibilityOff color={error ? "error" : "action"} /> : <Visibility color={error ? "error" : "action"} />}
									</IconButton>
								</InputAdornment>
							),
					  }
					: undefined
			}
		/>
    );
};

export default LinearTextInput;
