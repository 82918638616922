import { makeStyles } from "@mui/styles";

import Images from "../../store/images.js";

export const useHeaderStyles = (vendor) =>
	makeStyles((theme) => ({
		root: {
			position: "fixed",
			zIndex: 5,
			height: "69px",
			background: "#fafafa",
			padding: "20px",
		},
		logo: {
			position: "absolute",
			backgroundImage: `url(${Images(vendor).logo_large})`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "contain",
			width: "100%",
			height: "100%",
			maxWidth: "300px",
		},
	}));
