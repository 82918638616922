import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Button, Link, Grid, Typography } from "@mui/material";

import { useMainStyles } from "../../mainStyle.js";

import { loginUser } from "./loginController.js";
import { _t } from "../../store/translate.js";
import { getCurrentJSONData } from "../../store/auth.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import LinearTextInput from "../linearTextInput/linearTextInput.js";

function LoginPage(props) {
	const mainStyle = useMainStyles(props.vendor.postfix)();

	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState({ title: "", text: "" });
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = (event, navigate) => {
		event.preventDefault();
		var data = event.target.elements;
		dispatch(
			loginUser({
				data: {
					email: data.email.value,
					password: data.password.value,
				},
			})
		).then((response) => {
			if (response.payload) {
				if (response.payload._id) {
					dispatch(getCurrentJSONData()).then((res) => {
						if (res.payload && res.payload.data) {
							navigate("/projectList");
						} else {
							var title = _t("Es existiert keine jsonData");
							var text = _t("Bitte kontaktieren Sie den Support");
							dispatch({ type: "error_modal", payload: { show: true, title: title, text: text } });
						}
					});
				} else {
					let title = _t("");
					let text = response.payload;
					setModalText({ title, text });
					setOpenModal(true);
				}
			} else {
				let title = _t("Ungültige Eingaben");
				let text = _t("Das Passwort oder die E-Mail-Adresse ist falsch");
				dispatch({ type: "error_modal", payload: { show: true, title: title, text: text } });
			}
		});
	};

	/* 	const getOldVersion = () => {
		switch (props.vendor.postfix) {
			case "p&m": {
				return "https://xtrafloor.pfeiffer-may.de";
			}
			case "viega": {
				return "https://fonterra-schnellauslegung.viega.de";
			}
			case "buderus": {
				return "https://linear-eu.herokuapp.com/landing/vi841";
			}
			case "roth": {
				return "https://quickcal.roth-werke.de";
			}
			case "schuetz": {
				return "https://quickcal.schuetz.net";
			}
			case "henco": {
				return "https://tool.hencofloor.be";
			}
			case "henco_nl": {
				return "https://tool.henco-floor.nl";
			}
			case "ivt": {
				return "https://www.ivt-flaechenheizung-schnellauslegung.de";
			}
			case "kermi": {
				return "https://xnet.kermi.de";
			}
			default: {
				return "https://app-flaechenheizung.linear.explicatis.eu";
			}
		}
	}; */

	React.useEffect(() => {
		async function fetchData() {}
		fetchData();
	}, []);

	return (
		<Grid className={mainStyle.paper} style={{ paddingTop: 14 }}>
			{openModal && <ModalDialog vendor={props.vendor} opened={true} closed={setOpenModal} title={modalText.title} text={modalText.text} />}
			<Avatar className={mainStyle.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				{_t("Anmelden")}
			</Typography>
			<form
				className={mainStyle.form}
				noValidate
				onSubmit={(e) => {
					handleSubmit(e, navigate);
				}}
			>
				<LinearTextInput required label={_t("Email Addresse")} id="email" fullParentWidth autoComplete="email" autoFocus />
				<LinearTextInput required label={_t("Passwort")} id="password" fullParentWidth type="password" autoComplete="curren-password" />
				{/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
				<Button type="submit" fullWidth variant="contained" color="primary" className={mainStyle.submit}>
					{_t("Anmelden")}
				</Button>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Link style={{ cursor: "pointer" }} onClick={() => props.onClickResetPassword()} variant="body2">
							{_t("Passwort vergessen?")}
						</Link>
					</Grid>
					<Grid item>
						<Link variant="body2" style={{ cursor: "pointer" }} onClick={() => props.onClickRegister()}>
							{_t("Noch nicht registriert?")}
						</Link>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
}

export default LoginPage;
