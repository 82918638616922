import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Button, Paper, Grid, Typography, CircularProgress, Link } from "@mui/material";

import { useMainStyles } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";

import { resetPassword, updatePassword, verifyResetPasswordToken } from "./resetPasswordController.js";
import { _t } from "../../store/translate.js";
import { useDispatch } from "react-redux";
import { getCurrentJSONData } from "../../store/auth.js";
import LoadingOverlay from "../loadingOverlay/loadingOverlay.js";
import LinearTextInput from "../linearTextInput/linearTextInput.js";

function ResetPassword(props) {
	const mainStyle = useMainStyles(props.vendor.postfix)();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { email, token } = useParams();

	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState({ title: "", text: "" });
	const [isVerified, setIsVerified] = useState(false);

	const handleSubmit = async (event, navigate) => {
		event.preventDefault();
		setLoading(true);
		if (isVerified) {
			var password = event.target.elements.password.value;
			dispatch(updatePassword(email, password, token)).then((res) => {
				if (res.payload && res.payload._id) {
					dispatch(getCurrentJSONData()).then((res2) => {
						navigate("/projectList");
					});
				}
			});
		} else {
			var response = await resetPassword(event?.target?.elements?.email?.value);
			if (response?.status === 200) {
				var title = "";
				var text = response?.data;
				setModalText({ title, text });
				setOpenModal(true);
			}
		}
		setLoading(false);
	};

	const [loading, setLoading] = React.useState(false);
	React.useEffect(() => {
		async function fetchData() {
			if (props.user.user) {
				navigate("/");
			} else {
				if (email && token) {
					var request = await verifyResetPasswordToken(email, token);
					if (request) {
						setIsVerified(true);
					} else {
						navigate("/");
					}
				}
			}
		}
		fetchData();
	}, [props]);

	return (
		<>
			{openModal && <ModalDialog vendor={props.vendor} opened={true} closed={setOpenModal} title={modalText.title} text={modalText.text} />}
			{isVerified ? (
				<Layout>
					{loading ? (
						<CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />
					) : (
						<Grid container>
							<Grid item xs={false} sm={4} md={7} className={mainStyle.image} />
							<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
								<div className={mainStyle.paper}>
									<Avatar className={mainStyle.avatar}>
										<LockOutlinedIcon />
									</Avatar>
									<Typography component="h1" variant="h5">
										{_t("Passwort zurücksetzen")}
									</Typography>
									<form
										className={mainStyle.form}
										noValidate
										onSubmit={(e) => {
											handleSubmit(e, navigate);
										}}
									>
										<div>
											<LinearTextInput disabled value={email} label={_t("Email Addresse")} id="email" fullParentWidth autoComplete="email" autoFocus />
											<LinearTextInput required label={_t("Passwort")} id="password" fullParentWidth type="password" autoComplete="curren-password" />
										</div>

										<Button type="submit" fullWidth variant="contained" color="primary" className={mainStyle.submit}>
											{_t("Bestätigen")}
										</Button>
									</form>
								</div>
							</Grid>
						</Grid>
					)}
				</Layout>
			) : (
				<div style={{ position: "relative" }}>
					<Grid className={mainStyle.paper} style={{ paddingTop: 14 }}>
						<Avatar className={mainStyle.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							{_t("Passwort zurücksetzen")}
						</Typography>
						<form
							className={mainStyle.form}
							noValidate
							onSubmit={(e) => {
								handleSubmit(e, navigate);
							}}
						>
							<LinearTextInput required label={_t("Email Addresse")} id="email" fullParentWidth autoComplete="email" autoFocus />

							<Button type="submit" fullWidth variant="contained" color="primary" className={mainStyle.submit}>
								{_t("Bestätigen")}
							</Button>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Link
										style={{ cursor: "pointer" }}
										variant="body2"
										onClick={() => {
											props.onClickLogin();
										}}
									>
										{_t("Zurück zur Anmeldung")}
									</Link>
								</Grid>
							</Grid>
						</form>
						{loading ? <LoadingOverlay /> : undefined}
					</Grid>
				</div>
			)}
		</>
	);
}

export default ResetPassword;
