import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Info } from "@mui/icons-material";
import { Grid, Paper, Typography, ImageListItem, ImageListItemBar, IconButton, ImageList } from "@mui/material";

import { useMainStyles } from "../../mainStyle.js";
import { useHomeStyles } from "./homeStyle.js";
import Layout from "../../layout/layout.js";
import Images from "../../store/images.js";

import { Format, _t } from "../../store/translate.js";

import { ModalDialog } from "../shared/modals/modalDialog.js";
import Login from "./loginView.js";
import RegisterView from "../register/registerView.js";
import ResetPassword from "../resetPassword/resetPasswordView.js";

function Home(props) {
	let location = useLocation();

	const [viewOption, setViewOption] = useState("login");
	const vendor = props.vendor || {};
	const mainStyle = useMainStyles(vendor.postfix)();
	const homeStyle = useHomeStyles();

	const { feature1, feature2, feature3 } = Images(vendor.postfix);
	const featureList = [
		{
			id: "feature1",
			img: feature1,
			title: _t("Zugriff von überall"),
			text: Format(
				_t(
					"%1 wurde entwickelt, um ohne Hürden im Arbeitsalltag zur Verfügung zu stehen. Deshalb läuft es in jedem Webbrowser, egal ob zuhause am PC, unterwegs auf dem Smartphone oder beim Kunden vor Ort auf einem Tablet Computer. Alle Ergebnisse lassen sich als PDF ausgeben, zum Versand per E-Mail oder zum Drucken."
				),
				[vendor.title]
			),
			cols: 2,
		},
		{
			id: "feature2",
			img: feature2,
			title: _t("Umfassende Berechnung"),
			text: Format(
				_t(
					"%1 berechnet die Fußbodenheizung gemäß der DIN1264. Hierbei wird die Produktpalette von %1 als Grundlage genommen, für alle wichtigen Parameter existieren Voreinstellungen. Erzielen Sie Ihre gewünschten Leistungswerte mit Leichtigkeit."
				),
				[vendor.title]
			),
			cols: 2,
		},
		{
			id: "feature3",
			img: feature3,
			title: _t("Intelligente Materialermittlung"),
			text: Format(
				_t(
					"Vergessen Sie nie wieder Material oder Zubehör bei der Bestellung. %1 ermittelt voll automatisch alle benötigten Materialien für ein Projekt, summiert die verwendeten Mengen, optimiert die Wahl der Packungsgrößen und erlaubt die Ausgabe von Bestelllisten als druckbares PDF."
				),
				[vendor.title]
			),
			cols: 2,
		},
	];

	const [showFeatureText, setShowFeatureText] = useState("");
	function setTextView(id, onMouseEnter) {
		var showId = onMouseEnter ? id : "";
		setShowFeatureText(showId);
	}

	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState({ title: "", text: "" });

	const setVendorColor = () => {
		if (vendor.postfix === "buderus" || vendor.postfix === "cosmo") {
			return "white";
		}
		return "black";
	};

	React.useEffect(() => {
		async function fetchData() {
			if (location?.state) {
				if (location.state.justRegistered) {
					setModalText({
						title: `${_t("Fast geschafft")}...`,
						text: _t(
							"Ein Bestätigungslink wurde an Ihr E-Mail-Konto gesendet. Bitte klicken Sie auf den Link, um Ihre E-Mail-Adresse zu bestätigen und den Registrierungsprozess fortzusetzen."
						),
					});
					setOpenModal(true);
				} else if (location.state.justVerified) {
					setModalText({ title: _t("Benutzer verifiziert"), text: "" });
					setOpenModal(true);
				}
			}
		}
		fetchData();
	}, [location]);

	return (
		<div>
			<div style={{ position: "absolute" }}>
				<Layout>
					{openModal && <ModalDialog vendor={vendor} opened={true} closed={setOpenModal} title={modalText.title} text={modalText.text} />}
					<Grid container direction="row">
						<Grid container direction="row" item xs={false} md={6} className={`${mainStyle.image} ${homeStyle.app}`} alignItems="flex-end">
							<Grid
								item
								style={{
									backdropFilter: "contrast(60%) blur(80px)",
									color: setVendorColor(),
									padding: "40px",
								}}
							>
								<Typography component="h5" variant="h5">
									{vendor.title}
								</Typography>
								<Typography variant="subtitle1" paragraph>
									{_t("Der bequeme Weg zur schnellen Materialermittlung")}
								</Typography>
								<Typography paragraph align="justify" variant="body2">
									{Format(
										_t(
											"Unabhängig von fest installierter Software gibt die neue %1 Web-Applikation jetzt die Möglichkeit einer Online-Planung der Fußbodenheizung zur Angebotskalkulation. Kinderleicht lassen sich ganze Projekte erfassen, inklusive aller Geschosse, Wohnungen und Räume. %1 unterstützt Sie dabei mit bequemen Voreinstellungen, sowie einfacher und schneller Eingabe."
										),
										[vendor.title]
									)}
								</Typography>
							</Grid>
						</Grid>
						<Grid className={homeStyle.root} item xs={12} md={6} component={Paper} elevation={6}>
							<ImageList rowHeight={270} className={homeStyle.gridList} cols={3}>
								{featureList.map((feature) => (
									<ImageListItem key={feature.id} onMouseEnter={() => setTextView(feature.id, true)} onMouseLeave={() => setTextView(feature.id, false)}>
										<div
											style={{
												background: `url(${feature.img}) center/cover no-repeat`,
												width: "100%",
												height: "100%",
											}}
										>
											{showFeatureText === feature.id ? (
												<div>
													<div className={homeStyle.featureText}>
														<Typography paragraph>{feature.title}</Typography>
														<Typography align="justify" variant="body2">
															{feature.text}
														</Typography>
													</div>
													<ImageListItemBar style={{ height: "100%" }}></ImageListItemBar>
												</div>
											) : (
												<ImageListItemBar
													title={feature.title}
													actionIcon={
														<IconButton aria-label={`info about ${feature.title}`} className={homeStyle.icon} size="large">
															<Info />
														</IconButton>
													}
												/>
											)}
										</div>
									</ImageListItem>
								))}
							</ImageList>
							{!props?.user?.user &&
								(viewOption === "login" ? (
									<Login
										vendor={vendor}
										location={location}
										onClickRegister={() => setViewOption("register")}
										onClickResetPassword={() => setViewOption("resetPassword")}
									/>
								) : viewOption === "register" ? (
									<RegisterView vendor={vendor} location={location} onClickAlreadyRegistered={() => setViewOption("login")} />
								) : viewOption === "resetPassword" ? (
									<ResetPassword vendor={vendor} location={location} onClickLogin={() => setViewOption("login")} user={props.user} match={props.match} />
								) : undefined)}
						</Grid>
					</Grid>
				</Layout>
			</div>
		</div>
	);
}
export default Home;
