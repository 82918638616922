// IVT
import background_ivt from "../images/background_ivt.jpg";
import feature1_ivt from "../images/feature1_ivt.jpg";
import feature2_ivt from "../images/feature2_ivt.jpg";
import feature3_ivt from "../images/feature3_ivt.jpg";
import logo_ivt from "../images/logo_ivt.png";
import logo_ivt_large from "../images/logo_ivt_large.png";

// Buderus
import background_buderus from "../images/background_buderus.jpg";
import feature1_buderus from "../images/feature1_buderus.jpg";
import feature2_buderus from "../images/feature2_buderus.jpg";
import feature3_buderus from "../images/feature3_buderus.jpg";
import logo_buderus from "../images/logo_buderus.png";
import logo_buderus_large from "../images/logo_buderus_large.png";

// Henco
import background_henco from "../images/background_henco.jpg";
import feature1_henco from "../images/feature1_henco.jpg";
import feature2_henco from "../images/feature2_henco.jpg";
import feature3_henco from "../images/feature3_henco.jpg";
import logo_henco from "../images/logo_henco.png";
import logo_henco_large from "../images/logo_henco_large.png";

// Henco NL
import background_henco_nl from "../images/background_henco_nl.jpg";
import feature1_henco_nl from "../images/feature1_henco_nl.jpg";
import feature2_henco_nl from "../images/feature2_henco_nl.jpg";
import feature3_henco_nl from "../images/feature3_henco_nl.jpg";
import logo_henco_nl from "../images/logo_henco_nl.png";
import logo_henco_nl_large from "../images/logo_henco_nl_large.png";

// Henco NL
// import background_kermi from "../images/background_kermi.jpg";
// import feature1_kermi from "../images/feature1_kermi.jpg";
// import feature2_kermi from "../images/feature2_kermi.jpg";
// import feature3_kermi from "../images/feature3_kermi.jpg";
import logo_kermi from "../images/logo_kermi.png";
import logo_kermi_large from "../images/logo_kermi_large.png";

// P&M
import background_pm from "../images/background_p&m.jpg";
import feature1_pm from "../images/feature1_p&m.jpg";
import feature2_pm from "../images/feature2_p&m.jpg";
import feature3_pm from "../images/feature3_p&m.jpg";
import logo_pm from "../images/logo_p&m.png";
import logo_pm_large from "../images/logo_p&m_large.png";

// Purmo
import background_purmo from "../images/background_purmo.jpg";
import feature1_purmo from "../images/feature1_purmo.jpg";
import feature2_purmo from "../images/feature2_purmo.jpg";
import feature3_purmo from "../images/feature3_purmo.jpg";
import logo_purmo from "../images/logo_purmo.png";
import logo_purmo_large from "../images/logo_purmo_large.png";

// Voogel & Noot
import background_vn from "../images/background_v&n.jpg";
import feature1_vn from "../images/feature1_v&n.jpg";
import feature2_vn from "../images/feature2_v&n.jpg";
import feature3_vn from "../images/feature3_v&n.jpg";
import logo_vn from "../images/logo_v&n.png";
import logo_vn_large from "../images/logo_v&n_large.png";
// COSMO
import background_cosmo from "../images/background_cosmo.jpg";
import feature1_cosmo from "../images/feature1_cosmo.jpg";
import feature2_cosmo from "../images/feature2_cosmo.jpg";
import feature3_cosmo from "../images/feature3_cosmo.jpg";
import logo_cosmo from "../images/logo_cosmo.png";
import logo_cosmo_large from "../images/logo_cosmo_large.png";
import favicon_cosmo from "../images/favicon_cosmo.png";

// Roth
import background_roth from "../images/background_roth.jpg";
import feature1_roth from "../images/feature1_roth.jpg";
import feature2_roth from "../images/feature2_roth.jpg";
import feature3_roth from "../images/feature3_roth.jpg";
import logo_roth from "../images/logo_roth.png";
import logo_roth_large from "../images/logo_roth_large.png";

// Schuetz
import background_schuetz from "../images/background_schuetz.jpg";
import feature1_schuetz from "../images/feature1_schuetz.jpg";
import feature2_schuetz from "../images/feature2_schuetz.jpg";
import feature3_schuetz from "../images/feature3_schuetz.jpg";
import logo_schuetz from "../images/logo_schuetz.png";
import logo_schuetz_large from "../images/logo_schuetz_large.png";

// Somatherm
import background_somatherm from "../images/background_somatherm.jpg";
import feature1_somatherm from "../images/feature1_somatherm.jpg";
import feature2_somatherm from "../images/feature2_somatherm.jpg";
import feature3_somatherm from "../images/feature3_somatherm.jpg";
import logo_somatherm from "../images/logo_somatherm.png";
import logo_somatherm_large from "../images/logo_somatherm_large.png";

// Viega
import background_viega from "../images/background_viega.jpg";
import feature1_viega from "../images/feature1_viega.jpg";
import feature2_viega from "../images/feature2_viega.jpg";
import feature3_viega from "../images/feature3_viega.jpg";
import logo_viega from "../images/logo_viega.png";
import logo_viega_large from "../images/logo_viega_large.png";

function Images(VENDOR) {
	var background, feature1, feature2, feature3, favicon, logo, logo_large, primaryButtonColor;
	favicon = "";
	switch (VENDOR) {
		case "ivt":
			background = background_ivt;
			feature1 = feature1_ivt;
			feature2 = feature2_ivt;
			feature3 = feature3_ivt;
			logo = logo_ivt;
			logo_large = logo_ivt_large;
			primaryButtonColor = "";
			break;
		case "buderus":
			background = background_buderus;
			feature1 = feature1_buderus;
			feature2 = feature2_buderus;
			feature3 = feature3_buderus;
			logo = logo_buderus;
			logo_large = logo_buderus_large;
			primaryButtonColor = "";
			break;
		case "henco":
			background = background_henco;
			feature1 = feature1_henco;
			feature2 = feature2_henco;
			feature3 = feature3_henco;
			logo = logo_henco;
			logo_large = logo_henco_large;
			primaryButtonColor = "";
			break;
		case "henco_nl":
			background = background_henco_nl;
			feature1 = feature1_henco_nl;
			feature2 = feature2_henco_nl;
			feature3 = feature3_henco_nl;
			logo = logo_henco_nl;
			logo_large = logo_henco_nl_large;
			primaryButtonColor = "";
			break;
		case "kermi":
			// background = background_kermi;
			// feature1 = feature1_kermi;
			// feature2 = feature2_kermi;
			// feature3 = feature3_kermi;
			logo = logo_kermi;
			logo_large = logo_kermi_large;
			primaryButtonColor = "";
			break;
		case "p&m":
			background = background_pm;
			feature1 = feature1_pm;
			feature2 = feature2_pm;
			feature3 = feature3_pm;
			logo = logo_pm;
			logo_large = logo_pm_large;
			primaryButtonColor = "";
			break;
		case "purmo":
			background = background_purmo;
			feature1 = feature1_purmo;
			feature2 = feature2_purmo;
			feature3 = feature3_purmo;
			logo = logo_purmo;
			logo_large = logo_purmo_large;
			primaryButtonColor = "#e88c00";
			break;
		case "v&n":
			background = background_vn;
			feature1 = feature1_vn;
			feature2 = feature2_vn;
			feature3 = feature3_vn;
			logo = logo_vn;
			logo_large = logo_vn_large;
			primaryButtonColor = "#e88c00";
			break;
		case "cosmo":
			background = background_cosmo;
			feature1 = feature1_cosmo;
			feature2 = feature2_cosmo;
			feature3 = feature3_cosmo;
			logo = logo_cosmo;
			logo_large = logo_cosmo_large;
			favicon = favicon_cosmo;
			primaryButtonColor = "#da291c";
			break;
		case "roth":
			background = background_roth;
			feature1 = feature1_roth;
			feature2 = feature2_roth;
			feature3 = feature3_roth;
			logo = logo_roth;
			logo_large = logo_roth_large;
			primaryButtonColor = "";
			break;
		case "schuetz":
			background = background_schuetz;
			feature1 = feature1_schuetz;
			feature2 = feature2_schuetz;
			feature3 = feature3_schuetz;
			logo = logo_schuetz;
			logo_large = logo_schuetz_large;
			primaryButtonColor = "";
			break;
		case "somatherm":
			background = background_somatherm;
			feature1 = feature1_somatherm;
			feature2 = feature2_somatherm;
			feature3 = feature3_somatherm;
			logo = logo_somatherm;
			logo_large = logo_somatherm_large;
			primaryButtonColor = "";
			break;
		case "viega":
			background = background_viega;
			feature1 = feature1_viega;
			feature2 = feature2_viega;
			feature3 = feature3_viega;
			logo = logo_viega;
			logo_large = logo_viega_large;
			primaryButtonColor = "";
			break;

		default:
			break;
	}

	return { background, feature1, feature2, feature3, favicon, logo, logo_large, primaryButtonColor };
}

export default Images;
