import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Button, CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { useAdministrationStyles } from "./administrationStyle.js";
import { useMainStyles } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";

import { editVendor, getJsonsByVendor, getVendors, upsertVendors, uploadJSONFile } from "./administrationController.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import { vendorList } from "./vendorList.js";
import Cookies from "js-cookie";
import moment from "moment";

function Administration(props) {
	const administrationStyle = useAdministrationStyles();
	const mainStyle = useMainStyles(props.vendor.postfix)();

	const [currentVendor, setCurrentVendor] = React.useState({});

	const dispatch = useDispatch();

	const [vendors, setVendors] = React.useState({});

	const changedSelectedFile = (event, vendorId) => {
		if (event && event.target && event.target.files && event.target.files[0] && event.target.files[0].type === "application/json") {
			var newVendors = getStateVendors();
			var foundVendor = newVendors.find((v) => v._id === vendorId);
			foundVendor.selectedFile = {};
			foundVendor.selectedFile = event.target.files[0];
			setVendors(newVendors);
		}
	};
	const changeSelectedLang = (event, vendorId) => {
		if (event && event.target && event.target.value) {
			var newVendors = getStateVendors();
			var foundVendor = newVendors.find((v) => v._id === vendorId);
			foundVendor.selectedLang = event.target.value;
			setVendors(newVendors);
		}
	};
	const uploadFile = async (vendor) => {
		var lang = vendor.selectedLang || vendor.default_language;
		if (vendor.selectedFile) {
			var formData = new FormData();
			formData.append("jsonFile", vendor.selectedFile);
			formData.append("language", lang);
			formData.append("vendorId", vendor._id);
			let name = vendor.selectedFile.name ? vendor.selectedFile.name.split(".json")[0] : "";
			formData.append("name", name);
			//TODO getCurrent jsonData when vendor == current
			var isCurrentVendor = currentVendor.vendor === vendor.postfix && currentVendor.lang === lang;

			dispatch(uploadJSONFile(formData, isCurrentVendor)).then((response) => {
				if (response) {
					let uploadedVendor = vendors.find((v) => {
						return v._id === response.payload.vendor;
					});
					let jsonObj = uploadedVendor.jsons.find((j) => {
						return j.language === response.payload.language;
					});
					jsonObj.obj = response.payload;
					setVendors(vendors);
					setUploadedModal(true);
				}
			});
		}
	};

	const getStateVendors = () => {
		var newVendors = [];
		vendors.forEach((newVendor) => {
			newVendors.push(newVendor);
		});
		return newVendors;
	};
	const toggleActiveVendor = async (vendorId, active) => {
		setLoading(true);
		var newVendors = getStateVendors();
		var foundVendor = newVendors.find((v) => v._id === vendorId);
		foundVendor.active = active;
		let jsons = foundVendor.jsons;
		delete foundVendor.jsons;
		await editVendor(vendorId, foundVendor);
		foundVendor.jsons = jsons;
		setVendors(newVendors);
		setLoading(false);
	};
	const addVendors = async () => {
		setLoading(true);
		let res = await upsertVendors(vendorList);
		console.log(res);
		fetchData();
	};

	const [uploadedModal, setUploadedModal] = React.useState(false);
	const [loading, setLoading] = React.useState(true);

	const setCurrentVendors = useCallback(async (currentVendors) => {
		for (const cVendor of currentVendors) {
			let jsons = await getJsonsByVendor(cVendor._id, cVendor.languages);
			cVendor.jsons = jsons;
		}
	}, []);

	const fetchData = useCallback(async () => {
		setCurrentVendor({ vendor: props.vendor.postfix, lang: Cookies.get("language") });
		var currentVendors = await getVendors();
		await setCurrentVendors(currentVendors);
		setVendors(currentVendors);
		setLoading(false);
	}, [setCurrentVendors]);

	React.useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Layout>
			{loading && <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />}
			{uploadedModal && <ModalDialog vendor={props.vendor} opened={true} closed={setUploadedModal} title={"Uploaded Successfull"} />}
			{!loading && (
				<Grid item xs={12}>
					<Grid container item xs={12} justifyContent="space-between" className={mainStyle.gridPadding}>
						<Typography component="h5" variant="h5">
							Administration
						</Typography>
					</Grid>
					<Grid container item xs={12} direction="column" className={mainStyle.gridPadding}>
						<Typography component="h6" variant="h6" paragraph>
							Add all Vendors (if not exist):
						</Typography>
						<Grid item container style={{ background: "#f0f0f0" }} spacing={2}>
							<Grid item>
								<Button variant="contained" component="label" color={vendorList.length > vendors.length ? "primary" : "inherit"} onClick={addVendors}>
									Upsert Vendors
								</Button>
							</Grid>
							<Grid item>
								<Typography component="div" variant="body1">
									{"(" + vendorList.length + ")"}
									{vendorList.map((vendor) => (
										<span key={vendor.postfix}> {vendor.name},</span>
									))}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={12} justifyContent="space-between" className={mainStyle.gridPadding}>
						<Typography component="h6" variant="h6" paragraph>
							Vendors {"(" + vendors.length + ")"}:
						</Typography>
						<Grid item container justifyContent="space-between" xs={12} className={administrationStyle.root}>
							{vendors.map((vendor) => (
								<Grid key={vendor._id} item xs={12}>
									<Grid container direction="row" spacing={2}>
										<Grid item>
											<Typography component="div" variant="h6" color={currentVendor.vendor === vendor.postfix ? "primary" : "initial"}>
												{vendor.postfix}
											</Typography>
										</Grid>{" "}
										<Grid item>
											<Button variant="contained" href={"/landing/" + vendor.secret} disabled={!vendor.active} target="_blank" rel="noopener noreferrer">
												GoTo
											</Button>
										</Grid>
										<Grid item>
											<Button
												variant="contained"
												component="label"
												color={vendor.active ? "primary" : "inherit"}
												onClick={(event) => {
													toggleActiveVendor(vendor._id, !vendor.active);
												}}
											>
												{vendor.active ? "is_active" : "is_deactive"}
											</Button>
										</Grid>
									</Grid>
									<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
										<Typography component="div" variant="body1" paragraph>
											Upload Json:
										</Typography>
										<Grid item container spacing={2}>
											<Grid item>
												<TextField
													select
													label="Lang"
													variant="outlined"
													value={vendor.selectedLang || vendor.default_language}
													onChange={(event) => {
														changeSelectedLang(event, vendor._id);
													}}
												>
													{vendor.languages.map((lang) => (
														<MenuItem key={lang} value={lang}>
															{lang}
														</MenuItem>
													))}
												</TextField>
											</Grid>
											<Grid item>
												<Button variant="contained" component="label">
													Select file
													<input
														type="file"
														hidden
														onChange={(event) => {
															changedSelectedFile(event, vendor._id);
														}}
													/>
												</Button>
											</Grid>
											<Grid item>
												<Typography component="div" variant="body1" style={{ paddingTop: "5px" }}>
													{(vendor.selectedFile && vendor.selectedFile.name) || "No file selected"}
												</Typography>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													component="label"
													disabled={!vendor.selectedFile}
													color="primary"
													onClick={(event) => {
														uploadFile(vendor);
													}}
												>
													Upload {vendor.name} {vendor.selectedLang || vendor.default_language}
												</Button>
											</Grid>
										</Grid>
									</Grid>
									<Grid container justifyContent="space-between" className={mainStyle.gridPadding}>
										<Typography component="div" variant="body1" paragraph>
											Current Jsons:
										</Typography>
										{vendor.jsons.map((json, index) =>
											json.obj ? (
												<Grid key={vendor._id + index + json.obj?._id} item container spacing={2}>
													<Grid item>
														<Typography component="div" variant="body1" paragraph>
															Lang: <b>{json.language}</b>
														</Typography>
													</Grid>

													<Grid item>
														<Typography component="div" variant="body1" paragraph>
															Created: <b>{moment(json.obj?.created_at).format("DD.MM.YYYY HH:mm")}</b>
														</Typography>
													</Grid>
													<Grid item>
														<Typography component="div" variant="body1" paragraph>
															Name: <b>{json.obj?.name}</b>
														</Typography>
													</Grid>
												</Grid>
											) : (
												<Grid key={vendor._id + index + json.obj?._id} item container spacing={2}>
													<Grid item>
														<Typography component="div" variant="body1" paragraph>
															Lang: <b>{json.language}</b>
														</Typography>
													</Grid>
													<Grid item>
														<Typography component="div" variant="body1" paragraph color="error">
															keine JSON DATA vorhanden
														</Typography>
													</Grid>
												</Grid>
											)
										)}
									</Grid>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			)}
		</Layout>
	);
}

export default Administration;
